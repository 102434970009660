import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { images } from '../../config/images';
import './Navbar.css';

const Navbar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-content">
        <Link to="/" className="logo-container">
          <img src={images.logo} alt="Blackridge Group" className="logo" />
        </Link>
        
        <ul className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/what-we-do">What We Do</Link></li>
          <li><Link to="/insights">Insights</Link></li>
          <li><Link to="/our-firm">Our Firm</Link></li>
          <li><Link to="/blog">Blog</Link></li>
          <li><Link to="/tools">Tools</Link></li>
        </ul>

        <Link to="/login" className={`client-login ${isMenuOpen ? 'active' : ''}`}>
          Client Login
        </Link>

        <button className="menu-toggle" onClick={toggleMenu} aria-label="Toggle menu">
          <span className={`hamburger ${isMenuOpen ? 'active' : ''}`}></span>
        </button>
      </div>
    </nav>
  );
};

export default Navbar; 