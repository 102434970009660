import React from 'react';
import './Insights.css';
import { Card, Button } from '../components/ui';
import { PageLayout } from '../components/layout/PageLayout';
import { Article } from '../types';
import { images } from '../config/images';

const Insights: React.FC = () => {
  const articles: Article[] = [
    {
      title: "2024 Financial Market Outlook",
      category: "Market Analysis",
      date: "March 15, 2024",
      summary: "An in-depth analysis of global market trends and predictions for the coming year.",
      readTime: "8 min read",
      image: images.finlaw
    },
    {
      title: "ESG Investing: The New Normal",
      category: "Investment Strategy",
      date: "March 12, 2024",
      summary: "How environmental, social, and governance factors are reshaping investment decisions.",
      readTime: "6 min read",
      image: images.finlaw
    },
    {
      title: "Regulatory Changes in Banking Sector",
      category: "Regulatory",
      date: "March 10, 2024",
      summary: "Key updates on banking regulations and their impact on financial institutions.",
      readTime: "5 min read",
      image: images.finlaw
    }
  ];

  return (
    <PageLayout title="Latest Insights">
      <div className="articles-container">
        {articles.map((article, index) => (
          <Card key={index} className="article-card" hoverable>
            <img src={article.image} alt={article.title} className="article-image" />
            <div className="article-content">
              <div className="article-metadata">
                <span className="article-category">{article.category}</span>
                <span className="article-date">{article.date}</span>
              </div>
              <h2>{article.title}</h2>
              <p>{article.summary}</p>
              <div className="article-footer">
                <span className="read-time">{article.readTime}</span>
                <Button variant="outline">Read More</Button>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </PageLayout>
  );
};

export default Insights; 