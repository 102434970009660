import React from 'react';
import './TopFold.css';
import Banner from '../components/Banner';
import { Navbar } from '../components/layout';
import { Section, Button } from '../components/ui';

const TopFold: React.FC = () => {
  return (
    <div className="header">
      <Banner />
      <Navbar />
      <Section
        background="dark"
        className="hero-section"
      >
        <div className="hero-content">
          <h1>Strategic Advisory. Tangible Results.</h1>
          <p>
            Every day, we leverage sharp expertise, insights, and relationships to deliver exceptional solutions.
            Our focus is to empower clients by combining financial and 
            legal advisory to overcome challenges, unlock opportunities,
            and achieve superior results.
          </p>
          <Button 
            variant="outline" 
            size="large"
            className="cta-button"
          >
            See Our Client Impact
          </Button>
        </div>
      </Section>
    </div>
  );
};

export default TopFold; 