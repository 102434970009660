import React from 'react';
import './DealsMade.css';
import { FaHandshake, FaChartLine, FaBuilding } from 'react-icons/fa';

const DealsMade = () => {
  const deals = [
    {
      date: '2024 | Debt',
      title: 'Advised and Structured Syndicated Loan of $200M for Energy Company.',
      icon: <FaHandshake className="deal-icon" />
    },
    {
      date: '2024 | Debt',
      title: 'Advised and Structured a $100M Loan for a Maritime Logistics Company.',
      icon: <FaChartLine className="deal-icon" />
    },
    {
      date: '2023 | M&A',
      title: 'Advised $200M in Debt and Equity for Packaging Company to acquire 3 competitors.',
      icon: <FaBuilding className="deal-icon" />
    },
  ];

  return (
    <div className="deals-made">
      <h2>Deals</h2>
      <div className="deals-container">
        {deals.map((deal, index) => (
          <div key={index} className="deal-card">
            <div className="deal-icon-container">
              {deal.icon}
            </div>
            <div className="deal-content">
              <p className="deal-date">{deal.date}</p>
              <p className="deal-title">{deal.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DealsMade;
