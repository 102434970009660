// src/Views/HomePage.js
import React from 'react';
import './HomePage.css';

import TopFold from './TopFold.tsx';
import { DealsMade } from '../components/deals';
import Footer from './Footer';
import NewsletterCTA from './NewsletterCTA';
import Contact from './Contact';

const HomePage = () => {
    return (
        <div className="cafe-homepage">
            <TopFold />
            <DealsMade />
            <NewsletterCTA />
            <Contact />
            <Footer />
        </div>
    );
};

export default HomePage;
