import React, { useState, useEffect } from 'react';
import { db, auth, collection, getDocs, query, orderBy, addDoc } from '../firebase';
import './Blog.css';
import Banner from '../components/Banner';
import { Navbar } from '../components/layout';
import Footer from './Footer';
import NewsletterCTA from './NewsletterCTA';
import Contact from './Contact';
import { BlogEditor, BlogPost } from '../components/blog';
import { COLLECTIONS, TEXT, DATE_FORMAT_OPTIONS } from '../utils/constants';

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchBlogPosts = async () => {
    try {
      const q = query(collection(db, COLLECTIONS.BLOG_POSTS), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      const posts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        date: new Date(doc.data().date).toLocaleDateString('en-US', DATE_FORMAT_OPTIONS)
      }));
      setBlogPosts(posts);
    } catch (error) {
      console.error('Error fetching blog posts:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  return (
    <div className="blog-page">
      <Banner />
      <Navbar />
      <div className="blog-content">
        <h1>Our Blog</h1>
        <BlogEditor onNewPost={fetchBlogPosts} />
        
        {loading ? (
          <div className="loading">{TEXT.LOADING}</div>
        ) : (
          <div className="blog-posts-container">
            {blogPosts.map((post) => (
              <BlogPost key={post.id} post={post} />
            ))}
          </div>
        )}
      </div>

      <div className="contact-newsletter-container">
        <NewsletterCTA />
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
