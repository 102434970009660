import React from 'react';
import './Footer.css';
import { FaLinkedin, FaInstagram, FaYoutube } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-sections">
          <div className="footer-column">
          </div>
          <div className="footer-column">
            <h3>CONNECT</h3>
            <div className="social-icons">
              <a href="https://www.linkedin.com/in/fernando-gonzalez-henr1993" target="_blank" rel="noopener noreferrer">
                <FaLinkedin className="social-icon" aria-label="LinkedIn" />
              </a>
              <a href="https://instagram.com/fergonz" target="_blank" rel="noopener noreferrer">
                <FaInstagram className="social-icon" aria-label="Instagram" />
              </a>
              <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
                <FaYoutube className="social-icon" aria-label="YouTube" />
              </a>
              <a href="https://x.com/fergonz1993" target="_blank" rel="noopener noreferrer">
                <FaXTwitter className="social-icon" aria-label="X" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 Blackridge Group. All rights reserved.</p>
        <ul className="footer-links">
          <li>Privacy and Cookies</li>
          <li>Your Privacy Choices</li>
          <li>Terms & Conditions</li>
          <li>Security & Fraud Awareness</li>
          <li>Regulatory Disclosures</li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
