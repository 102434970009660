import React from 'react';
import './Tools.css';
import Banner from '../components/Banner';
import { Navbar } from '../components/layout';
import { Section, Card, Button } from '../components/ui';
import Footer from './Footer';
import NewsletterCTA from './NewsletterCTA';
import Contact from './Contact';
import { Tool } from '../types';

const Tools: React.FC = () => {
  const toolsList: Tool[] = [
    {
      title: "DCF Financial Model",
      description: "Comprehensive Discounted Cash Flow model for company valuations with detailed projections and sensitivity analysis",
      fileType: "Excel",
      fileName: "dcf_financial_model.xlsx",
      icon: "💹"
    },
    {
      title: "Financial Model Template",
      description: "Standard financial model template for startup valuations",
      fileType: "Excel",
      fileName: "financial_model_template.xlsx",
      icon: "📊"
    },
    {
      title: "Due Diligence Checklist",
      description: "Comprehensive checklist for M&A due diligence",
      fileType: "PDF",
      fileName: "due_diligence_checklist.pdf",
      icon: "📋"
    },
    {
      title: "Investment Memo Template",
      description: "Professional template for investment memorandums",
      fileType: "Word",
      fileName: "investment_memo_template.docx",
      icon: "📝"
    }
  ];

  const handleDownload = (fileName: string): void => {
    const fileUrl = `/downloads/${fileName}`;
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="tools-page">
      <Banner />
      <Navbar />
      <Section
        title="Financial Tools & Templates"
        centered
        spacing="large"
      >
        <div className="tools-container">
          {toolsList.map((tool, index) => (
            <Card
              key={index}
              hoverable
              className="tool-card"
              onClick={() => handleDownload(tool.fileName)}
            >
              <div className="tool-icon">{tool.icon}</div>
              <h3>{tool.title}</h3>
              <p>{tool.description}</p>
              <span className="file-type">{tool.fileType}</span>
              <Button variant="primary">Download</Button>
            </Card>
          ))}
        </div>
      </Section>
      <NewsletterCTA />
      <Contact />
      <Footer />
    </div>
  );
};

export default Tools; 