import React from 'react';
import './WhatWeDo.css';
import Footer from './Footer';
import Contact from './Contact';
import NewsletterCTA from './NewsletterCTA';
import { Navbar } from '../components/layout';
import { Section, Card } from '../components/ui';
import { FaChartLine, FaMoneyBillWave, FaBalanceScale } from 'react-icons/fa';
import { Service } from '../types';

const WhatWeDo: React.FC = () => {
  const services: Service[] = [
    {
      title: "Investment Banking",
      description: "We provide strategic advice and innovative financing solutions to corporations, institutions, and governments worldwide.",
      icon: <FaChartLine className="service-icon" />
    },
    {
      title: "Legal Advisory",
      description: "Our team of experienced lawyers offers comprehensive legal advice on complex financial and corporate matters.",
      icon: <FaBalanceScale className="service-icon" />
    }
  ];

  return (
    <div className="what-we-do-page">
      <Navbar />
      <Section
        title="What We Do"
        centered
        background="white"
        spacing="large"
      >
        <div className="services-container">
          {services.map((service, index) => (
            <Card
              key={index}
              hoverable
              className="service-card"
            >
              {service.icon}
              <h2>{service.title}</h2>
              <p>{service.description}</p>
            </Card>
          ))}
        </div>
      </Section>
      <div className="contact-newsletter-container">
        <NewsletterCTA />
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default WhatWeDo; 