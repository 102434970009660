import React from 'react';
import { BaseComponentProps } from '../../types';
import '../../styles/shared.css';

export interface SectionProps extends BaseComponentProps {
  title?: string;
  subtitle?: string;
  centered?: boolean;
  background?: 'white' | 'light' | 'dark';
  maxWidth?: string | number;
  spacing?: 'small' | 'medium' | 'large';
}

const Section: React.FC<SectionProps> = ({
  children,
  className = '',
  style,
  title,
  subtitle,
  centered = false,
  background = 'white',
  maxWidth = '1200px',
  spacing = 'medium'
}) => {
  const sectionClasses = [
    'section',
    `section-bg-${background}`,
    `section-spacing-${spacing}`,
    centered ? 'text-center' : '',
    className
  ].filter(Boolean).join(' ');

  return (
    <section className={sectionClasses} style={{ ...style, maxWidth }}>
      <div className="section-content">
        {title && <h2 className="section-title page-title">{title}</h2>}
        {subtitle && <p className="section-subtitle">{subtitle}</p>}
        {children}
      </div>
    </section>
  );
};

export default Section; 