// Navigation
export const NAV_LINKS = [
  { path: '/', label: 'Home' },
  { path: '/what-we-do', label: 'What We Do' },
  { path: '/insights', label: 'Insights' },
  { path: '/our-firm', label: 'Our Firm' },
  { path: '/blog', label: 'Blog' },
  { path: '/tools', label: 'Tools' },
];

// Date formatting options
export const DATE_FORMAT_OPTIONS = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

// Firebase collections
export const COLLECTIONS = {
  BLOG_POSTS: 'blogPosts',
  NEWSLETTER: 'newsletter',
  CONTACTS: 'contacts',
};

// Common text
export const TEXT = {
  LOADING: 'Loading...',
  ERROR: 'An error occurred. Please try again.',
  SUCCESS: 'Operation completed successfully.',
};

// Breakpoints
export const BREAKPOINTS = {
  MOBILE: '480px',
  TABLET: '768px',
  DESKTOP: '1024px',
  WIDE: '1200px',
}; 