import React, { useState } from 'react';
import { Section, FormInput, Button } from '../components/ui';
import { useFormValidation, ValidationRules } from '../hooks/useFormValidation';
import './Contact.css';

type ContactForm = {
  [key: string]: string;
  name: string;
  email: string;
  subject: string;
  message: string;
}

const Contact: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const initialValues: ContactForm = {
    name: '',
    email: '',
    subject: '',
    message: '',
  };

  const validationRules: ValidationRules = {
    name: [
      { required: true, message: 'Name is required' },
      { minLength: 2, message: 'Name must be at least 2 characters' },
      { maxLength: 50, message: 'Name must be less than 50 characters' },
    ],
    email: [
      { required: true, message: 'Email is required' },
      { pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Invalid email address' },
    ],
    subject: [
      { required: true, message: 'Subject is required' },
      { minLength: 5, message: 'Subject must be at least 5 characters' },
      { maxLength: 100, message: 'Subject must be less than 100 characters' },
    ],
    message: [
      { required: true, message: 'Message is required' },
      { minLength: 10, message: 'Message must be at least 10 characters' },
      { maxLength: 1000, message: 'Message must be less than 1000 characters' },
    ],
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    validateForm,
    resetForm,
  } = useFormValidation<ContactForm>(initialValues, validationRules);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      // Replace with actual API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      setSubmitSuccess(true);
      resetForm();
      setTimeout(() => setSubmitSuccess(false), 3000);
    } catch (error) {
      console.error('Failed to send message:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Section className="contact-section">
      <h2>Contact Us</h2>
      <p>Get in touch with our team for any inquiries or assistance.</p>
      
      <form onSubmit={handleSubmit} className="contact-form">
        <FormInput
          name="name"
          label="Name"
          value={values.name}
          error={errors.name}
          onChange={handleChange}
          onBlur={handleBlur}
          required
        />

        <FormInput
          type="email"
          name="email"
          label="Email"
          value={values.email}
          error={errors.email}
          onChange={handleChange}
          onBlur={handleBlur}
          required
        />

        <FormInput
          name="subject"
          label="Subject"
          value={values.subject}
          error={errors.subject}
          onChange={handleChange}
          onBlur={handleBlur}
          required
        />

        <FormInput
          type="textarea"
          name="message"
          label="Message"
          value={values.message}
          error={errors.message}
          onChange={handleChange}
          onBlur={handleBlur}
          required
        />

        <Button
          type="submit"
          disabled={isSubmitting}
          className="submit-button"
        >
          {isSubmitting ? 'Sending...' : 'Send Message'}
        </Button>

        {submitSuccess && (
          <div className="success-message" role="alert">
            Message sent successfully!
          </div>
        )}
      </form>
    </Section>
  );
};

export default Contact; 