import React from 'react';
import { BaseComponentProps } from '../../types';
import styles from './FormInput.module.css';

export interface FormInputProps extends BaseComponentProps {
  type?: 'text' | 'email' | 'password' | 'textarea';
  name: string;
  label: string;
  value: string;
  error?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const FormInput: React.FC<FormInputProps> = ({
  className = '',
  style,
  type = 'text',
  name,
  label,
  value,
  error,
  placeholder,
  required = false,
  disabled = false,
  onChange,
  onBlur,
}) => {
  const inputClasses = [
    styles.input,
    error ? styles.error : '',
    className
  ].filter(Boolean).join(' ');

  const renderInput = () => {
    const commonProps = {
      id: name,
      name,
      value,
      onChange,
      onBlur,
      className: inputClasses,
      placeholder,
      disabled,
      required,
      'aria-describedby': error ? `${name}-error` : undefined,
    };

    if (type === 'textarea') {
      return (
        <textarea
          {...commonProps}
          rows={4}
        />
      );
    }

    return (
      <input
        {...commonProps}
        type={type}
      />
    );
  };

  return (
    <div className={styles.formGroup} style={style}>
      <label htmlFor={name} className={styles.label}>
        {label}
        {required && <span className={styles.required}>*</span>}
      </label>
      {renderInput()}
      {error && (
        <span 
          className={styles.errorText} 
          id={`${name}-error`}
          role="alert"
        >
          {error}
        </span>
      )}
    </div>
  );
};

export default FormInput; 