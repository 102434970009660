import React from 'react';
import './OurFirm.css';
import Banner from '../components/Banner';
import { Navbar } from '../components/layout';
import { Section, Card } from '../components/ui';
import Footer from './Footer';
import NewsletterCTA from './NewsletterCTA';
import Contact from './Contact';
import { TeamMember, Stats } from '../types';

interface FirmInfo {
  overview: {
    title: string;
    description: string;
  };
  stats: Stats[];
  leadership: TeamMember[];
}

const OurFirm: React.FC = () => {
  const firmInfo: FirmInfo = {
    overview: {
      title: "Who We Are",
      description: "Blackridge Group is a leading financial and legal advisory firm headquartered in Panama City. We combine deep industry knowledge with specialized expertise in banking, investment management, and legal advisory services.",
    },
    stats: [
      { number: "15+", label: "Years of Experience" },
      { number: "$0", label: "Assets Under Management" },
      { number: "50+", label: "Successful Transactions" },
      { number: "1+", label: "Professional Experts" }
    ],
    leadership: [
      {
        name: "Fernando Gonzalez",
        position: "Chief Executive Officer",
        image: "placeholder"
      },
      {
        name: "Fernando Gonzalez",
        position: "Chief Legal Officer",
        image: "placeholder"
      },
      {
        name: "Fernando Gonzalez",
        position: "Head of Investment Banking",
        image: "placeholder"
      }
    ]
  };

  return (
    <div className="our-firm-page">
      <Banner />
      <Navbar />
      <Section
        title={firmInfo.overview.title}
        subtitle={firmInfo.overview.description}
        centered
        spacing="large"
      >
        <div className="stats-section">
          <div className="stats-container">
            {firmInfo.stats.map((stat, index) => (
              <Card key={index} className="stat-card" hoverable>
                <h2>{stat.number}</h2>
                <p>{stat.label}</p>
              </Card>
            ))}
          </div>
        </div>

        <Section
          title="Our Leadership"
          centered
          spacing="large"
          className="leadership-section"
        >
          <div className="leadership-container">
            {firmInfo.leadership.map((leader, index) => (
              <Card key={index} className="leader-card" hoverable>
                <div className="leader-image placeholder-image" />
                <h3>{leader.name}</h3>
                <p>{leader.position}</p>
              </Card>
            ))}
          </div>
        </Section>
      </Section>

      <div className="contact-newsletter-container">
        <NewsletterCTA />
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default OurFirm; 