import React from 'react';
import { BaseComponentProps } from '../../types';
import '../../styles/shared.css';

export interface CardProps extends BaseComponentProps {
  hoverable?: boolean;
  onClick?: () => void;
}

const Card: React.FC<CardProps> = ({
  children,
  className = '',
  style,
  hoverable = false,
  onClick
}) => {
  const cardClasses = [
    'card',
    hoverable ? 'hover-lift' : '',
    className
  ].filter(Boolean).join(' ');

  return (
    <div 
      className={cardClasses}
      style={style}
      onClick={onClick}
      role={onClick ? 'button' : undefined}
      tabIndex={onClick ? 0 : undefined}
    >
      {children}
    </div>
  );
};

export default Card; 