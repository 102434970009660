import React from 'react';
import Banner from '../Banner';
import Navbar from './Navbar';
import { Section } from '../ui';
import Footer from '../../Views/Footer';
import NewsletterCTA from '../../Views/NewsletterCTA';
import Contact from '../../Views/Contact';

interface PageLayoutProps {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
  showBanner?: boolean;
  centered?: boolean;
  spacing?: 'small' | 'medium' | 'large';
  background?: 'white' | 'dark';
}

export const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  title,
  subtitle,
  showBanner = true,
  centered = true,
  spacing = 'large',
  background = 'white'
}) => {
  return (
    <div className="page">
      {showBanner && <Banner />}
      <Navbar />
      <Section
        title={title}
        subtitle={subtitle}
        centered={centered}
        spacing={spacing}
        background={background}
      >
        {children}
      </Section>
      <div className="contact-newsletter-container">
        <NewsletterCTA />
        <Contact />
      </div>
      <Footer />
    </div>
  );
}; 