import React from 'react';
import { BaseComponentProps } from '../types';
import './Banner.css';

interface BannerProps extends BaseComponentProps {
  message?: string;
}

const Banner: React.FC<BannerProps> = ({ 
  className = '', 
  style,
  message = 'Welcome to Blackridge Group'
}) => {
  return (
    <div className={`banner ${className}`.trim()} style={style}>
      <div className="banner-content">
        <h1>{message}</h1>
      </div>
    </div>
  );
};

export default Banner; 