import React, { useState } from 'react';
import { db } from '../../firebase';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import './BlogEditor.css';

const BlogEditor = ({ onNewPost }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [author, setAuthor] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!title || !content || !author) return;

    setIsSubmitting(true);
    try {
      const docRef = await addDoc(collection(db, 'blog_posts'), {
        title,
        content,
        author,
        createdAt: Timestamp.now(),
        date: new Date().toISOString(),
      });
      
      setTitle('');
      setContent('');
      setAuthor('');
      if (onNewPost) onNewPost();
    } catch (error) {
      console.error('Error saving blog post:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="blog-editor">
      <form onSubmit={handleSubmit}>
        <div className="blog-inputs-container">
          <input
            type="text"
            className="blog-title-input"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <input
            type="text"
            className="blog-author-input"
            placeholder="Author"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            required
          />
        </div>
        <textarea
          className="blog-content-input"
          placeholder="Write your blog post here..."
          value={content}
          onChange={(e) => setContent(e.target.value)}
          rows={10}
          required
        />
        <button
          type="submit"
          className="publish-button"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Publishing...' : 'Publish'}
        </button>
      </form>
    </div>
  );
};

export default BlogEditor;
